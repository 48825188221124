.movie-poster {
  width: 100%;
  height: 100%;
}

.poster-container {
  width: 234px;
  height: 364px;
  float: left;
  position: relative;
  margin-bottom: 0.8rem;
}

.poster-container.list {
  margin-left: -1rem;
}

.widgets-container {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.release-date {
  display: inline-block;
  float: right;
  background-color: rgba(17, 17, 17, 0.7);
  color: #fff;
  padding: 0.15rem 0.5rem;
  border-radius: 3px;
  font-size: 11px;
}

.movie-title {
  font-size: 1.3em;
  padding-top: 1.5em;
}

.movie-title-list {
  display: inline-block;
  padding-bottom: 2rem;
  font-size: 2em;
}

.movies-main-header {
  text-align: center;
  margin-top: 20px;
}

.movie {
  margin-bottom: 2em;
}

.movie .poster-container {
  transform: scale(1);
  transition: 0.5s;
  cursor: pointer;
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.2);
}

.movie .poster-container:hover {
  transform: scale(1.05) translateY(-5px);
  transition: 0.5s;
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.4);
}

.movie-genre {
  color: rgb(146, 146, 146);
  transition: 0.3s;
}

.movie-genre span:hover {
  color: #51c0a4;
  transition: 0.3s;
}

.movie-genre span {
  margin-right: 0.2rem;
  font-size: 0.8rem;
  cursor: pointer;
}

li {
  list-style-type: none;
}

.inline-form {
  display: flex;
  justify-content: space-between;
}

.full-poster-image {
  margin: 0 auto;
}

.movie-genre-list {
  margin-top: -2rem;
  margin-left: 0.2rem;
}

.text-container-list {
  padding-left: 2rem;
  padding-top: 2rem;
  width: 750px;
}

.list-movie-overview {
  float: right;
}

.release-date-list {
  font-weight: 200;
  margin-top: -2rem;
  margin-bottom: 2rem;
  color: #777;
  font-size: 1rem;
}

.movie-poster-list {
  float: left;
}

.movie-list {
  background-color: #f9f9f9;
  border-radius: 3px;
}

.text-container-grid {
  width: 234px;
  margin-top: 20rem;
}

.float-right {
  float: right;
}
.files input {
  border: 0;
}
.color input {
  background-color: #f1f1f1;
}
.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}
.MuiTableCell-root {
  padding: 6px !important;
}
.MuiTableCell-root input {
  width: 100%;
}
.offset-md-3 {
  margin-left: 2% !important;
}
.MuiTableCell-alignRight {
  text-align: left !important;
  flex-direction: row-reverse;
}
.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 2px;
  padding: 0;
}
.container,
.container-sm {
  max-width: 540px;
}
.jumbotron {
  padding: 1rem 2rem;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 14px;
}
.paper {
  margin-top: 12vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar {
  margin: 10px;
  background-color: rgb(220, 0, 78) !important;
}
.form {
  width: 100%;
  margin-top: 10px;
}
.submit {
  margin-top: 20px !important;
  padding: 12px !important;
  margin-bottom: 10px !important;
}
.imagesBox {
  margin-top: 10px;
  margin-right: 10px;
  max-width: 36vw;
  min-width: 36vw;
  overflow: hidden;
}

.imagesBox img {
  width: 100%;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 0;
}
.css-1pcexqc-container {
  width: 500px;
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #ced4da;
}
.MuiTableCell-stickyHeader {
  z-index: 0 !important;
}
